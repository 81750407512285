import { Fade, Box, Typography, Container } from '@mui/material';
import React, { useEffect } from 'react'
import useImagePreloader from '../../hooks/useImagePreloader';
import HomeLayout from '../../Layouts/HomeLayout';

import bull_bg_2 from "../../img/bgs/bull_bg_2.png";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase-config';

function About() {
  const preloadSrcList: string[] = [
    bull_bg_2
  ];
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    logEvent(analytics, 'visit_site_about');
  }, []);
  return (
    <div>
      {!imagesPreloaded ? <>
        <Fade in={imagesPreloaded === false} timeout={500}>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width: "100vw",
              textAlign: "center",
              alignItems: "center",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <Box className="animate-flicker" sx={{
              // textAlign: "center",
              // height: "100vh",
              // marginLeft: "auto", marginRight: "auto",
              // alignItems: "center",
              // verticalAlign: "middle",
              // justifyContent: "center",
            }}>
              <Typography variant="h5"> Loading ... </Typography>
            </Box>
          </Box>
        </Fade>
      </> : <>
        <Fade in={imagesPreloaded === true} timeout={500}>
          <HomeLayout activeRoute={"/about"} title={"About"} preloaded>
            <Container>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography variant={"h2"}>About</Typography>

                  <Typography variant={"body1"}>
                    asTEErius is a clothing and apparel brand serving designs that casually say "I'm a furry" founded in late 2022.
                  </Typography>

                  <Typography variant={"body1"}>
                    Our name comes from a fusion of "tee" (as in tee-shirt) and the near-eponymous bull-headed demi-human from Greek mythology, "Asterius". 
                  </Typography>

                  <Box
                    sx={{
                      userSelect: "none",
                    }}
                  >
                    <img src={bull_bg_2} alt={"bull"}
                      style={{
                        width: "100%"
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Container>
          </HomeLayout>
        </Fade>
      </>}
    </div>
  )
}

export default About