import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Background, Parallax } from 'react-parallax'
import { theme } from '../..'
import HomeLayout from '../../Layouts/HomeLayout'

import bnuybg_mp4 from "../../img/bnuybg.mp4";
import bnuy_bg_1 from "../../img/bgs/bnuy_bg_1.png"
import bull_bg_1 from "../../img/bgs/bull_bg_1.png"

import { Button, Divider, Fade, Typography } from '@mui/material'
import useImagePreloader from '../../hooks/useImagePreloader'

import "./loading.css";
import { Link } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../firebase-config'

// eslint jsx-a11y/alt-text: 0

const preloadSrcList: string[] = [
  bnuybg_mp4,
  bnuy_bg_1
];

function Home() {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    logEvent(analytics, 'visit_site_home');
  }, []);
  return (
    <div>
      {!imagesPreloaded ? <>
        <Fade in={imagesPreloaded === false} timeout={500}>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width: "100vw",
              textAlign: "center",
              alignItems: "center",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <Box className="animate-flicker" sx={{
              // textAlign: "center",
              // height: "100vh",
              // marginLeft: "auto", marginRight: "auto",
              // alignItems: "center",
              // verticalAlign: "middle",
              // justifyContent: "center",
            }}>
              <Typography variant="h5"> Loading ... </Typography>
            </Box>
          </Box>
        </Fade>
      </> : <>
        {/* <Fade in={imagesPreloaded === true} timeout={500}> */}
          <HomeLayout activeRoute={"/"} preloaded fadeNavIn>
            {/* <Container> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Parallax strength={500} blur={100}>
                  <Background>
                    <video
                      playsInline
                      autoPlay
                      muted
                      loop
                      // poster="bnuybg.jpg"
                      // id="bgvid"
                      style={{
                        objectFit: "fill",
                        width: "100vw",
                        // position: "fixed",
                        marginTop: window.innerWidth > theme.breakpoints.values.sm ? "-25vw" : "inherit",
                        // filter: "blur(50px) hue-rotate(300deg)",
                        // filter: "blur(50px)",
                        filter: "hue-rotate(300deg)",
                      }}
                    >
                      {/* <source src="bnuybg.webm" type="video/webm" /> */}
                      <source src={bnuybg_mp4} type="video/mp4" />
                    </video>
                  </Background>
                  <Box
                    sx={{
                      height: "500px",
                      padding: "1rem",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        marginLeft: "auto", marginRight: "auto"
                      }}
                    >
                      <Typography
                        sx={{
                          // textShadow: "1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue"
                          fontFamily: "'Rubik Vinyl', cursive",
                          userSelect: "none",
                        }}
                        variant={"h2"}
                      >
                        asTEErius
                      </Typography>
                      <Divider variant={"middle"} flexItem sx={{ borderBottomWidth: 5, marginTop: "1rem", marginBottom: "1rem" }} />
                      <Typography
                        sx={{
                          // textShadow: "1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue"
                          fontFamily: "'Rubik Vinyl', cursive",
                          userSelect: "none",
                        }}
                        variant={"h3"}
                      >
                        Designs
                      </Typography>
                      <Typography
                        sx={{
                          // textShadow: "1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue"
                          fontFamily: "'Rubik Vinyl', cursive",
                          userSelect: "none",
                          fontWeight: "bold"
                        }}
                        variant={"h3"}
                      >
                        For Furries
                      </Typography>
                      <Typography
                        sx={{
                          // textShadow: "1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue"
                          fontFamily: "'Rubik Vinyl', cursive",
                          userSelect: "none",
                          fontWeight: "bolder"
                        }}
                        variant={"h3"}
                      >
                        By Furries
                      </Typography>
                    </Box>
                  </Box>
                </Parallax>
                
                <Parallax
                  blur={{ min: -15, max: 15 }}
                  // bgImage={bnuy_bg_1}
                  bgImageAlt="decorative"
                  strength={500}>
                  <Background>
                    <img src={bnuy_bg_1} alt={"bnuy"} style={{
                      filter: "hue-rotate(260deg)",
                      marginTop: "-30px",
                      marginLeft: window.innerWidth > theme.breakpoints.values.md ? "-500px" : undefined,
                      objectFit: "cover",
                    }} />
                  </Background>
                  <Box
                    sx={{
                      height: "500px",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  >
                    {/* <Paper
                      sx={{
                        backgroundColor: alpha(theme.palette.background.paper, 0.5),
                        margin: 0,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                      }}
                    > */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          top: "50%",
                          left: "50%",
                          position: "absolute",
                          transform: "translateX(-50%) translateY(-50%)",
                        }}
                      >
                        <Typography variant={"h3"} sx={{
                          userSelect: "none",
                        }}>Take Your Style <br /><span style={{ fontWeight: "600" }}>By The Horns</span></Typography>
                        <Typography variant={"body1"}>Look your best by sporting these hot, new designs!</Typography>
                        <Box>
                          <Button component={Link} to={"/designs"} variant="contained">Browse</Button>
                        </Box>
                      </Box>
                    {/* </Paper> */}
                  </Box>
                </Parallax>

                <Parallax
                  blur={{ min: -15, max: 15 }}
                  // bgImage={bnuy_bg_1}
                  bgImageAlt="decorative"
                  strength={500}>
                  <Background>
                    <img src={bull_bg_1} alt={"bull"} style={{
                      // filter: "hue-rotate(260deg)",
                      marginTop: "-30px",
                      // marginLeft: window.innerWidth > theme.breakpoints.values.md ? "-3600px" : "-2400px",
                      objectFit: "cover",
                    }} />
                  </Background>
                  <Box
                    sx={{
                      height: "500px",
                      padding: "1rem",
                      textAlign: "center",
                    }}
                  >
                    {/* <Paper
                      sx={{
                        backgroundColor: alpha(theme.palette.background.paper, 0.5),
                        margin: 0,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                      }}
                    > */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                          top: "50%",
                          left: "50%",
                          position: "absolute",
                          transform: "translateX(-50%) translateY(-50%)",
                        }}
                      >
                        <Typography variant={"h3"} sx={{
                          userSelect: "none",
                        }}>Want To Learn<br /><span style={{ fontWeight: "600" }}>Who We Are?</span></Typography>
                        <Box>
                          <Button component={Link} to={"/about"} variant="contained">About Us</Button>
                        </Box>
                      </Box>
                    {/* </Paper> */}
                  </Box>
                </Parallax>
                <Box sx={{ height: "25vh" }} />
              </Box>
            {/* </Container> */}
          </HomeLayout>
        {/* </Fade> */}
      </>}
    </div>
  )
}

export default Home