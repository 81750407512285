import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';
import { createTheme, Theme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import Routing from './Routing';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#7400B8',
    },
    secondary: {
      main: '#5E60CE',
    },
    background: {
      default: '#000000',
      paper: '#000000',
    },
    error: {
      main: '#4EA8DE',
    },
    warning: {
      main: '#64DFDF',
    },
    info: {
      main: '#80FFDB',
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif", // also available: "'Rubik Vinyl', cursive"
  },
};

export const theme: Theme = createTheme(themeOptions);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routing />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
