import React from 'react'
import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './Pages/About';
import Designs from './Pages/Designs';
import Home from './Pages/Home';

function Routing() {
  const RoutesConfig: Array<RouteObject> = [
    {
      path: "/",
      element: <Home />
    },
    {
      path: "/designs",
      element: <Designs />
    },
    {
      path: "/about",
      element: <About />
    },
    // {
    //   path: "*",
    //   element: <NotFoundPage />
    // }
  ];
  const router = createBrowserRouter(RoutesConfig);
  return (
    <RouterProvider router={router} />
  )
}

export default Routing