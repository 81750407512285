import { Fade, Box, Typography, Container, Paper, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import React, { useEffect, useState } from 'react'
import { designs } from '../../designs';
import { analytics } from '../../firebase-config';
import useImagePreloader from '../../hooks/useImagePreloader';
import { Design } from '../../interfaces';
import HomeLayout from '../../Layouts/HomeLayout';

function Designs() {
  const preloadSrcList: string[] = [
    ...designs.map((i) => i.preview_image), ...designs.map((i) => i.preview_thumb)
  ];
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  return (
    <div>
      {!imagesPreloaded ? <>
        <Fade in={imagesPreloaded === false} timeout={500}>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width: "100vw",
              textAlign: "center",
              alignItems: "center",
              verticalAlign: "middle",
              justifyContent: "center",
            }}
          >
            <Box className="animate-flicker" sx={{
              // textAlign: "center",
              // height: "100vh",
              // marginLeft: "auto", marginRight: "auto",
              // alignItems: "center",
              // verticalAlign: "middle",
              // justifyContent: "center",
            }}>
              <Typography variant="h5"> Loading ... </Typography>
            </Box>
          </Box>
        </Fade>
      </> : <>
        <Fade in={imagesPreloaded === true} timeout={500}>
          <HomeLayout activeRoute={"/designs"} title={"Designs"} preloaded>
            <Container>
              <Box>
                <Grid container>
                  {
                    designs.filter((e: Design) => e.enabled === true || e.enabled === undefined ).length === 0 && <>Coming Soon...</>
                  }
                  {designs.filter((e: Design) => e.enabled === true || e.enabled === undefined ).map((design: Design) => {
                    return (
                      <DesignGridItem design={design} />
                    )
                  })}
                </Grid>
              </Box>
            </Container>
          </HomeLayout>
        </Fade>
      </>}
    </div>
  )
}

function DesignGridItem({ design }: { design: Design }) {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  }
  useEffect(() => {
    logEvent(analytics, 'visit_site_designs');
  }, []);
  return (
    <Grid item key={design.id}>
      <Paper
        sx={{
          height: "175px",
          padding: "1rem"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem"
          }}
          onClick={() => {
            logEvent(analytics, 'click_open_design', {designId: design.id, designName: design.title});
            setOpen(true)
          }}
        >
          <img src={design.preview_thumb} alt={design.title} style={{ height: "100px" }} />
          <Typography variant="caption" sx={{ textAlign: "center" }} gutterBottom>{design.title}</Typography>
        </Box>
      </Paper>
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {design.title}
        </DialogTitle>
        <DialogContent>
          {design.description && <><DialogContentText id="alert-dialog-description">
            {design.description}
          </DialogContentText></>}
          <img src={design.preview_image} style={{ width: "100%" }} alt={design.title} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {design.purchaseLink && <>
            <Button variant="contained" component="a" href={design.purchaseLink} target="_blank" rel="noreferrer" onClick={() => {
              logEvent(analytics, 'click_purchase_design', {designId: design.id, designName: design.title});
            }}>
              Purchase
            </Button>
          </>}
          {(!design.purchaseLink && design.noLinkTxt) && <>
          <Typography>{design.noLinkTxt}</Typography>
          </>}
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default Designs