import { Designs } from "./interfaces";

import fang_thumb from "./img/designs/Fang_Preview_100.png"
import fang_image from "./img/designs/Fang_Preview_500.png"

export const designs: Designs = [
  {
    enabled: false,
    id: "fa1b6c6d-5173-4ca1-921f-8a7c047b9ad8",
    preview_thumb: fang_thumb,
    preview_image: fang_image,
    title: "Fang",
    description: `Unleash your inner animal and show those fangs! No one's gonna mess with you with some big ol' teefers like that!`,
    // purchaseLink: "https://google.com",
    noLinkTxt: "Coming Soon..."
  }
]